<template>
  <div>
    <vs-table
      multiple
      v-model="selected"
      max-items="50"
      :data="$store.state.MwbLeads"
    >
      <template slot="thead">
        <vs-th> Can-ID</vs-th>
        <vs-th>Person Name</vs-th>
        <vs-th>Course</vs-th>
        <vs-th>Batch</vs-th>
        <vs-th>City</vs-th>

        <vs-th>Agreed Cost</vs-th>
        <vs-th>Amount Paid</vs-th>
        <vs-th>Amount Pending</vs-th>
        <vs-th>Enrolled By</vs-th>
        <vs-th>Enrollment date</vs-th>
        <!-- <vs-th>Agreement Status</vs-th> -->
        <!-- <vs-th>Email Sent Status</vs-th> -->
        <vs-th>Info</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="12"
                >
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                    >{{ tr.person_name }}</vx-tooltip
                  >
                </vs-col>
              </vs-row>
            </vs-td>

            <vs-td>
              <div>{{ tr.course }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.batch }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.city }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.agreed_cost }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.amount_paid }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.amount_pending }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.added_by_name }}</div>
            </vs-td>

            <vs-td>
              <div>{{ unixTimestampConvert(tr.enrollment_date) }}</div>
            </vs-td>

            <!-- <vs-td>
              <div>{{ getAgreementStatus(tr.agreed_status) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getEmailStatus(tr.agreement) }}</div>
            </vs-td> -->

            <vs-td>
              <vs-row>
                <!-- <img
                  @click="openPopupEmit(tr)"
                  src="https://img.icons8.com/material-outlined/24/000000/info.png"
                />-->
                <div @click="openPopupEmit(tr)">
                  <vs-icon
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <!-- <div @click="openEditPopup(tr)">
                  <vs-icon
                    icon="edit"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div> -->
                <!-- <div v-if="tr.agreed_status === 0">
                  <vs-icon
                    icon="highlight_off"
                    size="small"
                    color="danger"
                  ></vs-icon>
                </div>
                <div v-if="tr.agreed_status === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="success"
                  ></vs-icon>
                </div>
                <div @click="Resendmail(tr)" v-if="tr.agreement !== null">
                  <img
                    src="https://img.icons8.com/android/24/000000/forward-arrow.png"
                  />
                </div> -->
                <!-- <img
                
                  src="https://img.icons8.com/color/24/000000/error.png"
                />-->

                <!-- <svg
                  v-if="tr.agreed_status === 1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 172 172"
                  style=" fill:#000000;"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none" />
                    <g fill="#2ecc71">
                      <path
                        d="M35.83333,21.5c-7.90483,0 -14.33333,6.4285 -14.33333,14.33333v100.33333c0,7.90483 6.4285,14.33333 14.33333,14.33333h100.33333c7.90483,0 14.33333,-6.4285 14.33333,-14.33333v-69.93099l-14.33333,14.33334l0.014,55.59765h-100.34733v-100.33333h84.26432l14.33333,-14.33333zM152.59961,23.59961l-73.76628,73.76628l-23.59961,-23.59961l-10.13411,10.13411l33.73372,33.73372l83.90039,-83.90039z"
                      />
                    </g>
                  </g>
                </svg>-->
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../eventbus.js";
// import axios from "axios";
import moment from "moment";
import enrollentbutton from "./buttons/AddEnrollmentButton.vue";
// import constants from "../../../constants.json";

Vue.config.productionTip = false;
export default {
  components: {
    enrollentbutton,
  },
  props: ["totalpagination"],
  data() {
    return {
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablelinks: 0,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      sent_status: "",
    };
  },
  mounted() {
    document
      .getElementsByClassName("vs-table--thead")[0]
      .addEventListener("click", this.selectallFunc, false);
    EventBus.$on("open-email-bdeleads", () => {
      if (this.selectall === false) {
        EventBus.$emit("open-email-engagement", this.selected);
      } else if (this.selectall == true) {
        EventBus.$emit("open-email-leadsdatatable", this.notselectedleads);
      }
    });
  },
  watch: {
    selected: function(value) {
      this.notselectedleads = [];
      this.$store.state.MwbLeads.forEach((lead) => {
        if (value.indexOf(lead) === -1) {
          this.notselectedleads.push(lead);
        }
      });
      // console.log(value)
    },
    currentTablePage: function(val) {
      this.getMwbLeads(val);
    },
  },
  methods: {
    Resendmail(tr) {
      EventBus.$emit("open-resend-email", tr);
    },
    getEmailStatus(agreement) {
      let returnValue = "";
      if (agreement !== null) {
        switch (agreement.sent_status) {
          case 0:
            returnValue = "Not Sent";
            break;
          case 1:
            returnValue = "Sent";
            break;
        }
        return returnValue;
      } else {
        return "old Data";
      }
    },
    getAgreementStatus(agreed_status) {
      switch (agreed_status) {
        case 0:
          return "Incomplete";
        case 1:
          return "Complete";
      }
    },
    getMode(mode) {
      switch (mode) {
        case "study_material_training":
          return "SM + T";
        case "study_material_only":
          return "SMO";
        case "only_license":
          return "OL";
      }
    },
    getSubject(subjects) {
      let arr = subjects.split(",");
      let newarr = [];
      arr.forEach((value) => {
        if (value !== "E&L") {
          newarr.push(value.charAt(0));
        } else {
          newarr.push(value);
        }
      });
      return newarr.join();
    },
    getImageSource(approved) {
      switch (approved) {
        case "":
          return "https://img.icons8.com/color/24/000000/error.png";
        case "Yes":
          return;
        case "No":
          return;
      }
    },
    selectallFunc() {
      this.selectall = !this.selectall;
    },
    openEnrollmentApproval(payload) {
      EventBus.$emit("openEmrollmentApprovalPopup", payload);
    },
    getMHPIndication(next_call) {
      // console.log(next_call);
      let today = new Date().setHours(0, 0, 0, 0);
      today = today.valueOf();
      let next_call_date = moment.unix(next_call).format("YYYY-MM-DD");
      today = moment.unix(today / 1000).format("YYYY-MM-DD");
      let comaprision = moment(next_call_date).isBefore(today);
      let same = moment(next_call_date).isSame(today);
      // console.log(next_call_date, today);
      // console.log(comaprision);
      if (comaprision || same) {
        let string =
          "https://img.icons8.com/color/24/000000/warning-shield.png";
        return string;
      } else {
        return "";
      }
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    // openEditPopup(payload) {
    //   EventBus.$emit("open-EditIimlEnrollmentPopup", payload);
    // },
    openPopupEmit(payload) {
      console.log(payload);
      this.openBigPopup(payload.mwb_id);
      // this.$vs.loading();
      // axios
      //   .get(`${constants.SERVER_API}getMwbLead?mwb_id=${payload.mwb_id}`, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     response.data.data.engagements = [];
      //     response.data.data.mobile = [];
      //     response.data.data.emails = [];
      //     this.$store.commit("PUSH_TO_WAITING", [response.data.data]);
      //     axios
      //       .get(
      //         `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.data.can_id}&person_id=${response.data.data.person_id}`,
      //         {
      //           headers: {
      //             Authorization: `Bearer ${localStorage.userAccessToken}`
      //           }
      //         }
      //       )
      //       .then(response2 => {
      //         this.$store.commit("UPDATE_NEW_DATA", [
      //           response.data.data,
      //           response2.data.engagements,
      //           response2.data.mobile,
      //           response2.data.emails
      //         ]);
      //         EventBus.$emit("open-student-details-popup", response.data.data);
      //         this.$vs.loading.close();
      //       })
      //       .catch(error => {
      //         this.handleError(error);
      //       });
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
      // this.$vs.loading({
      //   type: "corners"
      // });
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       payload,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     EventBus.$emit("open-student-details-popup", payload);
      //     if (emit !== undefined) {
      //       EventBus.$emit(emit);
      //     }
      //     this.$vs.loading.close();
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
    },
    addengagement(payload) {
      console.log("emitting");
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach((bde) => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    },
  },
};
</script>

<style></style>
